<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <!-- <filters :filtros="fields" :is-add.sync="isAdd" ref="filters"> </filters> -->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col lg="10" class="d-flex flex-column flex-lg-row justify-content-start">
              <div class="w-100 mb-1 mb-lg-0">
                <b-form-group label="Proyectos" label-for="restriction" class="mr-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="restriction"
                    :reduce="(proyectos) => proyectos.id"
                    placeholder="Proyectos"
                    v-model="project_id"
                    @input="selectProject(project_id)"
                    class="select-obra"
                  >
                  <template v-slot:selected-option="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div
                class="w-100 d-flex align-items-center h-100 justify-content-center justify-content-lg-start mb-1 mb-lg-0 flex-wrap flex-md-nowrap gap-1"
              >
                <b-button class="mr-2 mb-1 mb-md-0" variant="primary" @click="addRestric()">
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
                </b-button>
                <!-- <b-button variant="danger" class="mr-2 mb-1 mb-md-0" @click="generateReport">
                  <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> PDF </span>
                </b-button> -->
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="visibleFields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
          ref="selectableTable"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.top="'Editar'"
              variant="flat-success"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.noninteractive.hover.top="'Eliminar'"
              @click="deleteAction(data.item)"
              variant="flat-danger"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
          <template #cell(date_start)="data">
            <span>{{ validDate(data.item.date_start) }}</span>
          </template>
          <template #cell(date_end)="data">
            <span>{{ validDate(data.item.date_end) }}</span>
          </template>
          <template #cell(users)="data">
            <span>{{ repUsers(data.item.users) }}</span>
          </template>
          <template #cell(frecuency)="data">
            <span>{{ frecuencia(data.item.frecuency) }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-tareas-programadas"
        ref="modal-tareas-programadas"
        centered
        :title="isEdit ? 'Actualizar Tareas Programadas' : 'Agregar Tareas Programadas'"
        ok-only
        hide-footer
        size="lg"
        no-close-on-backdrop
        @hide="resetForm"
      >
      <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <form-wizard
              ref="wizard"
              color="#7367F0"
              :title="null"
              :subtitle="null"
              :next-button-text="nextButtonText"
              back-button-text="Atrás"
              finish-button-text="Finalizar"
              class="steps-transparent mb-3"
              @on-complete="saveData"
              @on-before-change="beforeTabChange"
            >
              <!-- account detail tab -->
              <tab-content
                title="Definicion."
                icon="feather icon-folder"
                :before-change="validEstablecer"
              >
                <b-row>
                  <b-col sm="12">
                    <validation-provider #default="{ errors }" name="name" rules="requeridoE">
                      <b-form-group label="Nombre de tarea programada" label-for="name">
                        <b-form-input
                          id="name"
                          trim
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          placeholder="Nombre de tarea programada"
                          v-model="items.name"
                        />
                        <small class="text-danger alert" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group label="Proyectos" label-for="restriction" class="requeridoE">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="proyectos"
                        label="code"
                        input-id="restriction"
                        :reduce="(proyectos) => proyectos.id"
                        placeholder="Proyectos"
                        v-model="items.project_id"
                        class="select-obra"
                        @input="selectProjectModal(items.project_id)"
                      >
                      <template v-slot:selected-option="option">
                          {{ option.code }} - {{ option.description }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.code }} - {{ option.description }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <validation-provider #default="{ errors }" name="alert" rules="requeridoE">
                      <b-form-group label="Consultas" label-for="alert">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="consultas"
                          multiple
                          label="name"
                          input-id="alert"
                          :reduce="(consultas) => consultas.id"
                          placeholder="Consultas"
                          v-model="items.alert"
                        >
                        </v-select>
                        <small class="text-danger alert" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="">
                    <validation-provider #default="{ errors }" name="users" rules="requeridoE">
                      <b-form-group label="Usuarios" label-for="users">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="optionsWithAll"
                          multiple
                          label="name"
                          input-id="users"
                          :reduce="(users) => users.email"
                          placeholder="Usuarios"
                          v-model="items.users"
                          @input="checkOptionAll"
                          :taggable="true"
                          class="fullInput"
                          @option:selecting="removeSelectedUser"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.name }}{{ option.id === 'all' ? '' : ' | ' + (option.email) }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.name }}{{ option.id === 'all' ? '' : ' | ' + (option.email) }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>                
                </b-row>
                <b-row>
                  <b-col md="12" class="">               
                      <b-form-group label="Desea enviar correo?" label-for="is_email">
                        <div class="d-flex w-50 align-items-center mt-1 ml-1">
                          <span class="mr-2">No</span>
                          <b-form-checkbox v-model="items.is_email" name="is_email" switch @click="goToNextTab">
                          </b-form-checkbox>
                          <span class="ml-2">Sí</span>
                        </div>
                        </b-form-group>              
                  </b-col>
                </b-row>
              </tab-content>     
              <tab-content title="Email" icon="feather icon-mail">
                
                <b-row v-show="items.is_email">
                  <b-col md="4" class="border-2 border-right">
                    <validation-provider
                      #default="{ errors }"
                      class="d-flex flex-column h-100 justify-content-start"
                      name="frecuency"
                    >
                      <b-form-radio v-model="items.frecuency" name="frecuency" value="daily" class="mb-1">
                        Diariamente
                      </b-form-radio>
                      <b-form-radio v-model="items.frecuency" name="frecuency" value="weekly" class="mb-1">
                        Semanalmente
                      </b-form-radio>
                      <b-form-radio v-model="items.frecuency" name="frecuency" value="monthly" class="mb-1">
                        Mensualmente
                      </b-form-radio>
                      <b-form-radio v-model="items.frecuency" name="frecuency" value="annually" class="">
                        Anualmente
                      </b-form-radio>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col md="8" class="d-flex flex-column">
                    <validation-provider #default="{ errors }" name="date_start"  :rules="{
                          requeridoE: items.is_email ? true : false
                        }">
                      <b-form-group label="Comenzar el" label-for="date_start">
                        <flat-pickr
                          @input="diferenDate(items.date_start, 1)"
                          id="date_start"
                          v-model="items.date_start"
                          :config="config1"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    
                    <!-- <div v-if="items.frecuency == 'weekly'" class="weekQtyInput">
                      <p>Repetir cada </p>
                      <validation-provider #default="{ errors }" name="weekQty" rules="requeridoE">
                        <b-form-group>
                          <b-form-input
                            id="weekQty"
                            type="number"
                            v-model="items.intervalWeeks"
                            style="width: 80px !important;"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <p> semanas el:</p>
                    </div> -->
                    <div v-if="items.frecuency == 'weekly'" class="weekQtyInput">
                        <b-form-group v-slot="{ ariaDescribedby }">
                          <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="items.daysOfWeek"
                            :options="optionsDays"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                          ></b-form-checkbox-group>

                        </b-form-group>
                    </div>
                    <div class="d-flex">
                      <span class="w-50 d-flex justify-content-start align-items-center">
                        <b-form-checkbox id="checkbox-1" v-model="addEnd" name="checkbox-1">
                          ¿Tiene fecha de fin?
                        </b-form-checkbox>
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="justify"
                        :rules="{
                          requeridoE: addEnd ? true : false
                        }"
                        class="w-100"
                      >
                        <b-form-group label="Fecha Fin" label-for="date_end" class="mb-0">
                          <b-form-group label="" label-for="date_end">
                            <flat-pickr
                              @input="diferenDate(items.date_end, 2)"
                              id="date_end"
                              v-model="items.date_end"
                              class="form-control"
                              :disabled="!addEnd"
                              :config="config2"
                              placeholder="Fecha Fin"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-show="items.is_email">        
                  <b-col sm="12">
                    <validation-provider #default="{ errors }" name="subject" rules="">
                      <b-form-group label="Asunto" label-for="subject">
                        <b-form-input
                          id="subject"
                          trim
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          placeholder="Asunto"
                          v-model="items.subject"
                        />
                        <small class="text-danger alert" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col sm="12">
                    <validation-provider #default="{ errors }" name="message" rules="">
                      <b-form-group label="Mensaje" label-for="message">
                        <!-- <b-form-textarea
                          id="actions"
                          placeholder="Mensaje"
                          rows="6"
                          max-rows="6"
                          no-resize
                          v-model="items.message"
                        ></b-form-textarea> -->
                        <quill-editor class="editor" v-model="items.message" :options="snowOption"> </quill-editor>
                        <small class="text-danger alert" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>   
                </b-row>
              </tab-content>
            </form-wizard>                  
          </b-form>
        </validation-observer>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import RestrictionsService from '@/services/RestrictionsService'
import ProjectsService from '@/services/ProjectsService'
import ScheduleService from '@/services/ScheduleService'
import UserService from '@/services/UserService'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import QueryService from '@/services/QueryService'
import moment from 'moment'
import 'moment-timezone'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      snowOption: {},
      addFile: false,
      addEnd: false,
      required,
      showLoading: false,
      isEdit: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
      options: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      config1: {
        minuteIncrement: 1,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        allowInput: true,
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        },
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ]
      },
      config2: {
        minuteIncrement: 1,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        altInput: true,
        allowInput: true,
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        },
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ]
      },
      fields: [
        { key: 'name', label: 'Nombre', sortable: false, visible: true },
        { key: 'frecuency', label: 'Frecuencia', sortable: false, visible: true },
        { key: 'date_start', label: 'F. Inicio', sortable: false, visible: true },
        { key: 'date_end', label: 'F. Fin', sortable: false, visible: true },
        { key: 'users', label: 'Usuarios', sortable: false, visible: true },
        { key: 'actions', label: 'Acciones', visible: true }
      ],
      selectedDays: [],
      optionsDays: [
        { value: 1, text: 'Lunes' },
        { value: 2, text: 'Martes' },
        { value: 3, text: 'Miércoles' },
        { value: 4, text: 'Jueves' },
        { value: 5, text: 'Viernes' },
        { value: 6, text: 'Sábado' },
        { value: 7, text: 'Domingo' }
      ],
      estados: [
        {
          text: 'Provisional',
          value: 'Provisional'
        },
        {
          text: 'Rechazada',
          value: 'Rechazada'
        },
        {
          text: 'Oficial',
          value: 'Oficial'
        },
        {
          text: 'Liberada',
          value: 'Liberada'
        },
        {
          text: 'Liberada por Usuario',
          value: 'Liberada por Usuario'
        },
        {
          text: 'Cancelada',
          value: 'Cancelada'
        }
      ],
      frecuency: [
        {
          text: 'Diariamente',
          value: 'daily'
        },
        {
          text: 'Semanalmente',
          value: 'weekly'
        },
        {
          text: 'Mensualmente',
          value: 'monthly'
        },
        {
          text: 'Anualmente',
          value: 'annually'
        }
      ],
      temp_date_start: null,
      temp_date_end: null,
      items: {
        name: '',
        is_email:false,
        frecuency: 'weekly',
        project_id: null,
        by_cowner:false,
        date_start: new Date(),
        date_end: new Date(),
        users: [],
        subject: '',
        message: '',
        alert: [],
        daysOfWeek: [],
        intervalWeeks: 0,
      },
      users: [],
      consultas: [],
      project_id: null,
      records: [],
      proyectos: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 100,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      activeStep: 1 
      
    }
  },
  components: {
    quillEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    nextButtonText() {
      return this.items.is_email ? 'Siguiente' : 'Finalizar';
    },
    optionsWithAll() {
      // Añadir la opción "Todos" al principio del array
      const optionsWithAll = [{ id: 'all', name: 'Todos', email: "all" }, ...this.users];
      return optionsWithAll;
    }
  },
  async mounted() {
    
    this.getSelect()
    this.getData()
    
  },
  watch: {
    'items.is_email': function(newVal, oldVal) {
      if (newVal) {
        this.goToNextTab();
      }
    },
  },
  methods: {
    validEstablecer() {
      return new Promise((resolve, reject) => {
      
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) { 
            if(!this.items.is_email){
              reject()
              this.saveData();
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    selectProjectModal(item) {
      console.log('item', item)
      this.getConsultas(item)
    },
    async selectProject(item) {
   
      this.arrayFilters = []
      if (item != null) {
        this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: item })

      
      }
      let params = {
        project_id:item,
        email: this.userData.email
      }
      let resp =  await QueryService.getQueryByUser(params, this.$store)
    

      
      this.getData()
    },
    
    validDate(fecha) {
     
      if (fecha != null) {
        const year =new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD HH:mm')
      }
      return ''
    },
    beforeTabChange(newTab, oldTab) {
      // Condición para cambiar el botón a "Finalizar" si items.is_email es false
      if (oldTab.title === 'Definicion.' && !this.items.is_email) {
        console.log("finish")
        this.$refs.wizard.setFinishButton(true);
      }
    },
    goToNextTab() {
      if(this.items.is_email){
        const wizard = this.$refs.wizard;
        wizard.nextTab();
      }
    },
    repUsers(users) {
      // const users = JSON.parse(this.items.users)
      // console.log('this.items.users',this.items.users)
      const arrayUsuarios = JSON.parse(users)
      let usuarios = ''
      for (let index = 0; index < arrayUsuarios.length; index++) {
        const element = arrayUsuarios[index]
        if (index == arrayUsuarios.length - 1) {
          usuarios += element
        } else {
          usuarios += element + ' - '
        }
      }
      return usuarios
    },
    frecuencia(item) {
      const freq = this.frecuency.filter((e) => e.value == item)
      if (freq.length > 0) {
        return freq[0].text
      }
      return ''
    },
    closeModal() {
      this.resetForm()
      this.$refs['modal-tareas-programadas'].hide()
    },
    checkOptionAll() {
      console.log("OPTIONS ALL",this.optionsWithAll)
      const allOption = this.optionsWithAll.find((option) => option.id === 'all');
      if (allOption && this.items.users.includes(allOption.id)) {
        this.items.users = this.users.map((user) => user.email);
      } else if (allOption) {
        const index = this.items.users.indexOf(allOption.id);
        if (index !== -1) {
          this.items.users.splice(index, 1);
        }
      }
      console.log("ITEMS USER", this.items.users)
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.addEnd = false
      this.temp_date_end = null
      this.temp_date_start = null
      this.consultas = []
      this.items = {
        frecuency: 'weekly',
        project_id: null,
        is_email:false,
        by_cowner : false,
        date_start: new Date(),
        date_end: new Date(),
        users: [],
        subject: '',
        message: '',
        alert: [],
        daysOfWeek: [],
        intervalWeeks: 0,
      }
    },
    diferenDate(fecha, val) {
      if (this.addEnd) {
        if (fecha != null) {
          if (val == 1) {
            let date1T = moment(fecha).format('yyyy-MM-DD')
            let date2T = moment(this.items.date_end).format('yyyy-MM-DD')
            let date1 = moment(date1T)
            let date2 = moment(date2T)
            if (date1.diff(date2, 'days') > 0) {
              this.$swal({
                icon: 'warning',
                title: 'Fecha inválida',
                text: 'La fecha de inicio no puede ser mayor a la fecha de fin',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
              console.log(676)
              this.items.date_start = moment(this.temp_date_start).format('yyyy-MM-DD')
            }
          } else {
            let date1T = moment(this.items.date_start).format('yyyy-MM-DD')
            let date2T = moment(fecha).format('yyyy-MM-DD')
            let date1 = moment(date1T)
            let date2 = moment(date2T)
            if (date2.diff(date1, 'days') < 0) {
              this.$swal({
                icon: 'warning',
                title: 'Fecha inválida',
                text: 'La fecha fin no puede ser menor a la fecha de inicio',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
              this.items.date_end = moment(this.temp_date_end).format('yyyy-MM-DD')
            }
          }
        }
      }
    },

    showFilters() {
      this.isAdd = true
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    
    addRestric() {
      if(JSON.parse(localStorage.getItem("project_id"))){
          console.log('JSON.parse(localStorage.getItem("project_id"))',JSON.parse(localStorage.getItem("project_id")))
          this.items.project_id = JSON.parse(localStorage.getItem("project_id"))
          this.selectProjectModal(this.items.project_id)
        }
      this.$refs['modal-tareas-programadas'].show()
    },
    removeSelectedUser(user) {
      if(this.items.users){
        const index = this.items.users.findIndex((u) => u == user.email);
        if (index !== -1) {
          this.items.users.splice(index, 1);
        }
        console.log("this.items.users", this.items.users)
      }
    },
    async saveData() {
      //TIENES CAMBIOS AQUI CON EL UTC     
      let data = this.items
     
      let dateInit =  new Date(data.date_start);
      dateInit.setHours(dateInit.getHours() - 5);
      console.log('enter')
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          console.log('data', data)
          this.isDisabled = true
          let resp = ''
          let datos = {}
          //const dateInLimaTimezone = moment.tz(data.date_start, 'America/Lima')
          datos.date_start = dateInit         
          //const dateInLimaTimezoneEnd = moment.tz(data.date_end, 'America/Lima')
          if(this.addEnd){
            let dateEnd =  new Date(data.date_end);
            dateEnd.setHours(dateEnd.getHours() - 5);

            datos.date_end =dateEnd;
          }else {
            datos.date_end  = null;
          }          
          datos.name = data.name
          datos.is_email = data.is_email
          datos.subject = data.subject
          datos.message = data.message
          datos.by_cowner = false
          datos.status = 'active'
          datos.project_id = data.project_id
          if(!data.is_email){
            data.frecuency = ''
          }
          datos.frecuency = data.frecuency
          datos.users = JSON.stringify(data.users)
          datos.alert = JSON.stringify(data.alert)
          if(datos.frecuency == "weekly"){
            if(data.daysOfWeek.length == 0){
              datos.daysOfWeek = "1"
            }else{
              datos.daysOfWeek = data.daysOfWeek.sort((a, b) => a - b).join(',')
            }
            /* datos.intervalWeeks = parseInt(data.intervalWeeks) */
            datos.intervalWeeks = 0
          }
          console.log('this.addEnd', this.addEnd)
          console.log('datos', datos)
          if (this.isEdit == false) {
            resp = await ScheduleService.saveSchedule(datos, this.$store)
          } else {
            let id = data.id
            resp = await ScheduleService.updateSchedule(id, datos, this.$store)
          }
          console.log('datoss', datos)
          console.log('resp', resp)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text: 'La tarea(s) programada(s) ha sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.getData()
            this.resetForm()
            this.$refs['modal-tareas-programadas'].hide()
          } else {
            this.$swal({
              title: 'Error!',
              text:
                ' Hubo un error al ' +
                (this.isEdit == true ? 'actualizar' : 'registrar') +
                ' la tarea(s) programada(s)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        }
      })
    },
    async getSelect() {
      const user = JSON.parse(localStorage.getItem('userData'))
      this.showLoading = true
      const url = `?limit=100`
      const resp = await ProjectsService.getProyectos(url, this.$store)
      console.log('resp projectos', resp)
      if (resp.status) {
        this.proyectos = resp.data.rows
        if(JSON.parse(localStorage.getItem("project_id"))){
          console.log('JSON.parse(localStorage.getItem("project_id"))',JSON.parse(localStorage.getItem("project_id")))
          this.project_id = JSON.parse(localStorage.getItem("project_id"))
        }
        else if (resp.data.rows.length > 0) {
          this.project_id = resp.data.rows[0].id
        } else {
          if (this.user_role == 'planner') {
            this.project_id = 0
          }
        }
        this.selectProject(this.project_id)
      }   
      this.showLoading = false
    },
    async getConsultas(id) {
      console.log('get consultas', id)
      this.consultas = [];
      this.items.users = [];
      this.items.alert = [];
      this.users = [];
      this.showLoading = true
      let url = ''
      if (id != null) {
        url = `?limit=100&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id },{ keyContains: 'by_cowner', key: 'equals', value: false }])
        const resp = await QueryService.getQuerys(url, this.$store)
        console.log('resp', resp)
        if (resp.status) {
          this.consultas = resp.data.rows
        }
       
        const respUsu = await UserService.getUsersByProject(id, this.$store)
        if (respUsu) {
          console.log('respUsu.data.rows', respUsu.data.rows)
          this.users = respUsu.data.rows
        }
      }
      this.showLoading = false
    },
    async getData() {
      this.showLoading = true
      this.arrayFilters = []
      console.log("ARRAY DE FILTERS", this.arrayFilters)
      this.arrayFilters.push({ keyContains: 'by_cowner', key: 'equals', value: false })
      this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: this.project_id })
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
      const resp = await ScheduleService.getSchedules(url, this.$store)
   
      if (resp) {
        console.log('resp.data.rows', resp.data.rows)
        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    edit(item) {
      console.log('item', item)
      let dateInit =  new Date( item.date_start);
      dateInit.setHours(dateInit.getHours() + 5);
      this.items.name = item.name
      this.items.is_email = item.is_email
      this.items.project_id = item.project_id
      this.items.name = item.name
      this.items.by_cowner = false
      if(!this.items.is_email){
        this.items.frecuency = 'weekly'
      }else{
        this.items.frecuency = item.frecuency
      }
      this.items.date_start = dateInit //item.date_start
      this.temp_date_start = dateInit //item.date_start
      this.items.subject = item.subject
      this.items.message = item.message
      console.log('this.validDate(item.date_end)', this.validDate(item.date_end))
      if (this.validDate(item.date_end) != '') {
        this.items.date_end = item.date_end
        this.temp_date_end = item.date_end
        this.addEnd = true
      }
   
      this.getConsultas(item.project_id)
      /* this.items.frecuency = item.frecuency */
      this.items.id = item.id
      this.items.users = JSON.parse(item.users)
      if (item.query_alert.length > 0) {
        item.query_alert.forEach((element) => {
          this.items.alert.push(element.query_id)
        })
        console.log("TEST")
      }
      if(item.daysOfWeek){
        this.items.daysOfWeek = item.daysOfWeek.split(',').map(Number)
        console.log('this.items.daysOfWeek',this.items.daysOfWeek)
      }
      this.items.intervalWeeks = item.interval
      this.$refs['modal-tareas-programadas'].show()
      this.isEdit = true
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar esta tarea programada?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      const resp = await ScheduleService.deleteSchedule(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'La tarea programada ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la tarea programada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.pad-export {
  padding: 0.52rem 1rem !important;
}
.editor {
  height: 180px !important;
  overflow-y: scroll;
}
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
.fullInput .vs__dropdown-toggle {
  overflow: hidden !important;
  flex-flow: nowrap !important;
  text-wrap: nowrap !important;
  height: auto !important;
}
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}
.weekQtyInput{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.weekQtyInput .custom-control{
  margin-top: 0.5rem !important;
}
</style>
